import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./containers/App";
import * as serviceWorker from "./libs/registerServiceWorker";
import {Provider} from "react-redux"
import store from "./store/store"
import {CookiesProvider} from 'react-cookie';
import packageJson from '../package.json';
import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'
import FallbackComponent from "./views/fallback-error-view/FallbackErrorView"
import {initializeFirebase} from "./utils/FirebaseUtils";
import ThemeProvider from "./components/theme-provider/ThemeProvider";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import GAListener from "./components/google-analytics/GAListener";
import './i18n';

// export const bugsnagClient = bugsnag('5185a62984a23027833a1a81f291a750')

// import Loadable from "react-loadable";
// const Loading = () => <div/>;
// const bugsnag = Loadable({
//     loader: () => import("bugsnag-js"),
//     loading: Loading
// });

if (window.Cypress) {
    window.store = store;
}

const stage = process.env.REACT_APP_STAGE || "local";
export const bugsnagClient = bugsnag({
    apiKey: '1070bf20154ed0c3a5895d8f12ff58d2',
    appVersion: packageJson.version,
    notifyReleaseStages: ["development", "staging", "production"],
    releaseStage: stage,
    autoCaptureSessions: true,
    maxBreadcrumbs: 40,
    // beforeSend: function (report) {
    //     try {
    //         let promiseValue = report.metaData.promise["rejection reason"];
    //         if (promiseValue === 'Brak wyników') {
    //             report.ignore()
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // },
    maxEvents: 50,
    consoleBreadcrumbsEnabled: process.env.NODE_ENV === 'production'
});
// bugsnagClient.notify(new Error('Test error'))

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

// const FallbackComponent2 = ({ error, info }) => (
//     <div>An error has occurred</div>
// );
const DEBUG = (process.env.REACT_APP_STAGE !== 'production');
// var DEBUG = !!packageJson.debug;
if (!DEBUG) {
    if (!window.console) window.console = {};
    const methods = ["log", "debug", "warn", "info", "time", "timeEnd"];
    for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {
        };
    }
}
ReactDOM.render(
    <CookiesProvider>
        <ThemeProvider>
            <Router>
                <GAListener>
                    <Provider store={store}>
                        <ErrorBoundary FallbackComponent={FallbackComponent}>
                            <ScrollToTop>
                                <App/>
                            </ScrollToTop>
                        </ErrorBoundary>
                    </Provider>
                </GAListener>
            </Router>
        </ThemeProvider>
    </CookiesProvider>,
    document.getElementById("root")
);
serviceWorker.register();
initializeFirebase();
